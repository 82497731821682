<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="10" lg="8">
                <b-card>
                    <h1 class="text-center mb-2">
                        <strong class="text-danger">
                            Rp {{ formatRupiahAkun(akun.saldo) }}
                        </strong>
                    </h1>
                    <table class="w-full table">
                        <tr>
                            <td>Nama Akun</td>
                            <td>:</td>
                            <th>{{ akun.nama }}</th>
                        </tr>
                        <tr>
                            <td>Nomor Akun</td>
                            <td>:</td>
                            <th>{{ akun.nomor }}</th>
                        </tr>
                        <tr>
                            <td>Jenis Akun</td>
                            <td>:</td>
                            <th>
                                <b-badge :variant="akun.jenis == 1 ? 'light-success' : 'light-danger'">
                                    {{ akun.jenis == 1 ? 'Debit' : 'Kredit' }}
                                </b-badge>
                            </th>
                        </tr>
                        <tr>
                            <td>Kategori Akun</td>
                            <td>:</td>
                            <th>{{ akun.kategori ? akun.kategori.kategori : '-' }}</th>
                        </tr>
                        <tr>
                            <td>Jenis Neraca</td>
                            <td>:</td>
                            <th>{{ akun.jenis_neraca ? akun.jenis_neraca.neraca : '-' }}</th>
                        </tr>
                        <tr>
                            <td>Pajak</td>
                            <td>:</td>
                            <th>{{ `${akun.pajak}%` }}</th>
                        </tr>
                        <tr>
                            <td>Akun Dimasukan Dalam Hutang</td>
                            <td>:</td>
                            <th>
                                <b-badge :variant="akun.hutang == 1 ? 'light-success' : 'light-danger'">
                                    {{ akun.hutang == 1 ? 'Ya' : 'Tidak' }}
                                </b-badge>
                            </th>
                        </tr>
                        <tr>
                            <td>Akun Ditampilkan di Laba Rugi</td>
                            <td>:</td>
                            <th>
                                <b-badge :variant="akun.laba_rugi == 1 ? 'light-success' : 'light-danger'">
                                    {{ akun.laba_rugi == 1 ? 'Ya' : 'Tidak' }}
                                </b-badge>
                            </th>
                        </tr>
                        <tr>
                            <td>Akun Ditampilkan di Neraca</td>
                            <td>:</td>
                            <th>
                                <b-badge :variant="akun.neraca == 1 ? 'light-success' : 'light-danger'">
                                    {{ akun.neraca == 1 ? 'Ya' : 'Tidak' }}
                                </b-badge>
                            </th>
                        </tr>
                    </table>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BOverlay, BRow, BCol, BCard, BButton, BBadge} from 'bootstrap-vue'
export default {
    components: {
        BOverlay, BRow, BCol, BCard, BButton, BBadge
    },
    data: () => ({
        loading: false,
        akun: null
    }),
    methods: {
        async getAkun() {
            const akuns = this.$store.state.akun.datas
            let akun = akuns.find(item => item.id == this.$route.params.id)
            if(!akun) {
                akun = await this.$store.dispatch('akun/getDataById', this.$route.params.id)
                if(!akun) {
                    this.$router.push('/akun')
                }
            }

            this.akun = akun
        }
    },
    async created() {
        this.loading = true
        await this.getAkun()
        this.loading = false
        if(this.$route.query.from) {
            this.$route.meta.breadcrumb[0].to = this.$route.query.from
        }
    }
}
</script>